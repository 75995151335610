<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        อนุมัติขายส่ง : SA2-2
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-date-picker
          class="form-control ant-date"
          placeholder="ค้นหาจากวันที่"
          v-model:value="dateSearch"
          format="DD/MM/YYYY"
          inputReadOnly
        />
      </div>
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหาจาก เลขที่เอกสาร / รหัสผู้ซื้อ"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ซื้อ</th>
              <th>ชื่อผู้ซื้อ</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="7">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <!-- <td>{{ !showing1 ? index + 1 : showing1 + index }}</td> -->
              <td>
                <a style="font-weight: bold" @click="goToItemDetail(item)"
                  ><u>
                    {{ item.sales_no ? item.sales_no : "" }}
                  </u>
                </a>
              </td>
              <td>{{ item.customer_code ? item.customer_code : "" }}</td>
              <td>{{ item.customer ? item.customer.customer_name : "" }}</td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>
              <td>{{ item.final_price }}</td>

              <td
                @mouseenter="handleHoverA()"
                @click="
                  role === 'STAFF'
                    ? ''
                    : item.status === '0'
                    ? handleApprove(item)
                    : ''
                "
                :style="
                  item.is_cancel === '1'
                    ? 'cursor: ; color: FireBrick; font-weight: 500'
                    : item.status === '1'
                    ? 'cursor: ; color: #3ca76c; font-weight: 500'
                    : item.status === '0'
                    ? 'cursor: pointer; color: #0098DA; font-weight: 500'
                    : 'cursor: ; color: red; font-weight: 500'
                "
              >
                {{
                  item.is_cancel === "1"
                    ? "ยกเลิกแล้ว"
                    : item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td>
              <td>
                <div class="row">
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i
                        :class="
                          item.status === '0'
                            ? 'bi bi-pencil-square'
                            : 'bi bi-eye-fill'
                        "
                      ></i>
                    </button>
                  </div>

                  <div class="col-sm-4" v-if="item.status === '0'">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>

                  <div
                    class="col-sm-4"
                    v-if="item.status === '1' && item.is_cancel === '0'"
                  >
                    <button
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="role === 'STAFF' ? '' : handleCancelOrder(item)"
                      @mouseenter="handleHoverB()"
                      style="color: red; cursor: pointer"
                    >
                      <i class="bi bi-x-circle"></i>
                    </button>
                  </div>
                  <div v-if="item.is_cancel === '1'" class="col-sm-4"></div>

                  <div @mousedown="hoverExportBtn()" class="col-sm-4">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="hoverA">
      <DialogConfirmByPass
        :dialogConfirmByPass="dialogConfirmByPass"
        @submitConfirmPass="submitConfirmPass"
        @closeDialogConformByPass="closeDialogConformByPass"
      />
    </div>

    <div v-if="hoverB">
      <DialogCancelOrder
        :dialogCancelOrder="dialogCancelOrder"
        @submitCancelOrder="submitCancelOrder"
        @closeDialogCancelOrder="closeDialogCancelOrder"
      />
    </div>

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import saleApi from "@/api/sale/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import DialogCancelOrder from "../components/dialogCancelOrder.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

import { Decode, Encode } from "@/services";

import pdfFile from "../../components/files/salesFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    DialogCancelOrder,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "BELUCA | อนุมัติขายส่ง";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    dialogCancelOrder: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    hoverA: false,
    hoverB: false,

    searchInput: "",
    dateSearch: "",
    allData: [],

    role: "",
  }),

  created() {
    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await saleApi.salesOrder.getAll({
          sales_type: "0",
          // status: "0",
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/sale/SA2-1/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        path: "/sale/SA2-1/edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.sales_no} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await saleApi.salesOrder.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove, method) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
        is_cancel: method === "cancel" ? "1" : "0",
      };

      updateResponse = await saleApi.salesOrder.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          this.updateProduct(method);
          Swal.fire({
            icon: `${method === "cancel" ? "error" : "success"}`,
            title: `${
              method === "cancel" ? "ยกเลิกรายการขายส่ง" : "อนุมัติสำเร็จ"
            }`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct(method) {
      const updateResponse = await whApi.product.multiUpdate(
        this.itemApprove.salesOrderItems,
        method === "cancel" ? "salesC" : "sales"
      );

      if (updateResponse.response_status === "ERROR") {
        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      }
      // let countArr = 0;
      // for (const element of this.itemApprove.salesOrderItems) {
      //   countArr += 1;
      //   await this.getOneProduct(element.product.id, element.item_amt, method);
      // }
    },
    async getOneProduct(id, item_amt, method) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data, method);
      }
    },
    async updateProductAmountRecieve(item_amt, item, method) {
      let updateResponse = [];
      let sumQty =
        method === "cancel"
          ? parseFloat(item.product_bring_qty) + parseFloat(item_amt)
          : parseFloat(item.product_bring_qty) - parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
        isUpdateQty: true,
      };

      updateResponse = await whApi.product.update(item.id, body);

      console.log({ updateResponse });
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },

    handleCancelOrder(item) {
      this.itemApprove = { ...item };
      this.dialogCancelOrder = true;
    },

    handleHoverA() {
      this.hoverA = true;
      this.hoverB = false;
    },
    handleHoverB() {
      this.hoverB = true;
      this.hoverA = false;
    },

    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove, "status");
      this.dialogConfirmByPass = false;
    },

    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },

    submitCancelOrder(isApprove) {
      this.statusApprove(isApprove, "cancel");
      this.dialogCancelOrder = false;
    },

    closeDialogCancelOrder() {
      this.dialogCancelOrder = false;
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },

    textSearch(val) {
      console.log({ val });
    },

    goToItemDetail(item) {
      this.$router.push({
        path: "/sale/SA2-1/edit",
        query: {
          id: item.id,
        },
      });
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },
    // Export file

    async search() {
      const newDate = this.dateSearch
        ? moment(this.dateSearch.$d).format("YYYY-MM-DD")
        : "";
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");
      this.loading = true;

      const responseSearch = await saleApi.salesOrder.search(
        {
          search: this.searchInput,
          date: newDate,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
          sales_type: "0",
        }
      );

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
